import telegram from '../../img/telega.svg'
import email from '../../img/email.svg'

import './contacts.css'

function Contacts() {
	return (
		<div className='contacts_block'>
			<a
				href='https://t.me/volkov130'
				className='contacts_link'
				target='_blank'
				rel='noreferrer'
			>
				<img className='logo_contacts' src={telegram} alt='logo' />
			</a>
			<a
				href='mailto:volkov1305701@yandex.ru'
				className='contacts_link'
				target='_blank'
				rel='noreferrer'
			>
				<img className='logo_contacts' src={email} alt='logo' />
			</a>
		</div>
	)
}

export default Contacts

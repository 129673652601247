import businessW from '../../img/businessW.svg'
import designW from '../../img/designW.svg'
import legalW from '../../img/legalW.svg'
import mobileW from '../../img/mobileW.svg'
import supportW from '../../img/supportW.svg'

import './description.css'

const elem1Desc = {
	img: businessW,
	title: 'Сайт визитка',
	fullDescription1:
		'В современном мире, где интернет является неотъемлемой частью нашей жизни, наличие собственного сайта является не просто приятным дополнением, но и необходимым инструментом для успешного продвижения бизнеса или представления своих работ. Однако, создание качественного и функционального сайта может оказаться непростой задачей. Именно здесь на помощь прихожу я – специалист по созданию сайтов-визиток и портфолио.',
	fullDescription2:
		'Индивидуальный подход: При создании сайта мы учитываем ваши индивидуальные потребности и пожелания, чтобы обеспечить максимально эффективное использование ресурса. Мы вместе выберем оптимальный дизайн, структуру и функционал сайта, который будет отражать вашу индивидуальность и стиль.',
}
const elem2Desc = {
	img: designW,
	title: 'Разработка дизайна',
	fullDescription1:
		'Персонализированный дизайн: Разработка сайтов визиток и портфолио требует определенного уровня творчества. Мы умеем сочетать эстетику и функциональность, чтобы создать уникальные и привлекательные сайты, которые отражают личность и стиль каждого клиента.',
	fullDescription2:
		'Каждый дизайн создается с учетом конкретных потребностей, целей и целевой аудитории клиента.',
}
const elem3Desc = {
	img: legalW,
	title: 'юридические услуги',
	fullDescription1:
		'Если вы предоставляете услуги или продаете товары через интернет, вам обязательно потребуются юридические документы, регулирующие отношения с клиентами. Мы можем составить такие документы как договор оферты, пользовательское соглашение, правила использования сайта или приложения, а также другие юридические “штучки”, которые обеспечат соблюдение ваших интересов и защиту от возможных претензий со стороны клиентов.',
	fullDescription2:
		'Мы имеем большой опыт работы в области юриспруденции и знаем, какие условия необходимо включить в документы, чтобы они были максимально эффективными. Мы также можем помочь с регистрацией товарного знака, защитой авторских прав и другими вопросами, связанными с интеллектуальной собственностью.',
}
const elem4Desc = {
	img: mobileW,
	title: 'Адаптивность',
	fullDescription1:
		'Мобильная оптимизация: В современном мире большинство людей пользуется мобильными устройствами для доступа в Интернет. Поэтому мои сайты автоматически адаптируются под различные устройства и экраны, обеспечивая безупречное отображение на смартфонах и планшетах.',
	fullDescription2:
		'Это гарантирует, что сайт будет доступен и привлекателен для посетителей, вне зависимости от их устройства.',
}
const elem5Desc = {
	img: supportW,
	title: 'поддержка',
	fullDescription1:
		'Мы предлагаем профессиональное сопровождение на всех этапах разработки, от начальной консультации до запуска сайта и последующую поддержку, чтобы обеспечить ваше полное удовлетворение и достижение поставленных целей.',
	fullDescription2:
		'После запуска сайта мы также предлагаем услуги поддержки и сопровождения, включая обновление контента, исправление возможных технических проблем, улучшение дизайна и структуры сайта, а также его продвижение и оптимизацию для поисковых систем.',
}

function DescriptionElement(props) {
	return (
		<div className='description_block'>
			<div className='container_decryption_title'>
				<img
					className='logo_description_info'
					src={props.data.img}
					alt='logo'
				/>
				<div className='wrp'>
					<h2 className='title_description'>{props.data.title}</h2>
					<p className='description_text'>{props.data.fullDescription1}</p>
					<p className='description_text'>{props.data.fullDescription2}</p>
				</div>
			</div>
		</div>
	)
}

export function Business() {
	return <DescriptionElement data={elem1Desc} />
}
export function Design() {
	return <DescriptionElement data={elem2Desc} />
}
export function Legal() {
	return <DescriptionElement data={elem3Desc} />
}
export function Mobile() {
	return <DescriptionElement data={elem4Desc} />
}
export function Support() {
	return <DescriptionElement data={elem5Desc} />
}

import {
	Support,
	Mobile,
	Legal,
	Design,
	Business,
} from './components/description/Description'
import Header from './components/header/Header'
import Footer from './components/footer/Footer'
import Main from './components/main/Main'
import Contacts from './components/contacts/Contacts'

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import ScrollToTop from './components/utils/scrollToTop'
import Menu from './components/menu/Menu'

function App() {
	return (
		<Router>
			<ScrollToTop />
			<Header />
			<Routes>
				<Route path='/' element={<Main />}></Route>
				<Route path='/support' element={<Support />}></Route>
				<Route path='/business' element={<Business />}></Route>
				<Route path='/mobile' element={<Mobile />}></Route>
				<Route path='/legal' element={<Legal />}></Route>
				<Route path='/contacts' element={<Contacts />}></Route>
				<Route path='/menu' element={<Menu />}></Route>
				<Route path='/design' element={<Design />}></Route>
			</Routes>
			<Footer />
		</Router>
	)
}

export default App

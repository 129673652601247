import businessB from '../../img/businessB.svg'
import designB from '../../img/designB.svg'
import legalB from '../../img/legalB.svg'
import mobileB from '../../img/mobileB.svg'
import supportB from '../../img/supportB.svg'
import btnB from '../../img/btnB.svg'
import btnBottom from '../../img/btnBottom.svg'

import './main.css'
import { NavLink } from 'react-router-dom'

const elements1 = {
	img: businessB,
	title: 'Сайт визитка',
	description:
		'Уникальный сайт, который представит вашу компанию, услуги и проекты в лучшем свете, привлечет новых клиентов и откроет новые возможности.',
	link: '/business',
}
const elements2 = {
	img: designB,
	title: 'Разработка дизайна',
	description:
		'Заказывайте сайт с индивидуальным дизайном! Либо разработаем дизайн с нуля, либо адаптируем ваш вариант.',
	link: '/design',
}
const elements3 = {
	img: legalB,
	title: 'юридические услуги',
	description:
		'Составим договор оферты, правила пользования и прочие юридические штучки под ваш проект.',
	link: '/legal',
}
const elements4 = {
	img: mobileB,
	title: 'Адаптивность',
	description:
		'С нами ваш сайт - идеальный на всех устройствах! Обеспечиваем адаптивную и мобильную вёрстку для наилучшего юзабилити.',
	link: '/mobile',
}
const elements5 = {
	img: supportB,
	title: 'поддержка',
	description:
		'Мы не оставим вас и всегда будем готовы расширить ваш функционал и внести изменения при необходимости.',
	link: '/support',
}

function ElementMain(props) {
	return (
		<div className='block_main_info'>
			<div className='background-element'></div>
			<img className='logo_main_info' src={props.data.img} alt='logo' />
			<div className='container_text'>
				<h2 className='title_main_info'>{props.data.title}</h2>
				<p className='description_info'>{props.data.description}</p>
			</div>
			<NavLink to={props.data.link}>
				<img className='logo_main_info' src={btnB} alt='logo' />
			</NavLink>
		</div>
	)
}

function BtnAdd() {
	return (
		<div className='container_btn'>
			<NavLink to='/contacts'>
				<img className='btn_add' src={btnBottom} alt='logo' />
			</NavLink>
		</div>
	)
}

function Main() {
	return (
		<main className='main'>
			<ElementMain data={elements1} />
			<ElementMain data={elements4} />
			<ElementMain data={elements2} />
			<ElementMain data={elements5} />
			<ElementMain data={elements3} />
			<BtnAdd />
		</main>
	)
}

export default Main

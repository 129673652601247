import copyright from '../../img/copyright.svg'
import './footer.css'

function Footer() {
	return (
		<footer className='footer'>
			<div className='copyright_footer'>
				<img className='copyright' src={copyright} alt='logo' />
				<h4 className='copyright_text'>ISIN LAB 2023</h4>
			</div>
		</footer>
	)
}

export default Footer

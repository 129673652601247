import './menu.css'
import { NavLink } from 'react-router-dom'

function Menu() {
	return (
		<div className='container_menu'>
			<nav className='wrp_menu_item'>
				<NavLink className='link' to='/'>
					<h2 className='item_menu'>главная</h2>
				</NavLink>
				<NavLink className='link' to='/contacts'>
					<h2 className='item_menu'>контакты</h2>
				</NavLink>
			</nav>
		</div>
	)
}

export default Menu

import menu from '../../img/menuW.svg'
import logo from '../../img/logoIsinW.svg'
import './header.css'

import { NavLink } from 'react-router-dom'

function Header() {
	return (
		<header className='header'>
			<NavLink to='/'>
				<img className='logo_header' src={logo} alt='logo' />
			</NavLink>
			<nav className='header_nav'>
				<NavLink className='header_link' to='/'>
					главная
				</NavLink>
				{/* <NavLink className='header_link' to='/projects'>
					портфолио
				</NavLink> */}
				<NavLink className='header_link' to='/contacts'>
					контакты
				</NavLink>
			</nav>
			<NavLink className='hamburger_menu' to='/menu'>
				<img className='hamburger_menu_img' src={menu} alt='menu' />
			</NavLink>
		</header>
	)
}

export default Header
